import {SignOutButton, useUser} from "@clerk/clerk-react";
import React, {useEffect} from "react";

const NoOrgFound = () => {
    const {user} = useUser()
    const [email, setEmail] = React.useState(null)

    useEffect(() => {
        if(user){
            let _email = user.emailAddresses[0].emailAddress
            setEmail(_email)
        }
    }, [user])

    if(!user){
        return <span className="loader"></span>
    }

    return (
        <div>
            <p>Account Email: {email}</p>
            <p>No organization was found for this account.</p>
            <p>Please try signing out and signing in again. </p>
            <p>If this problem persists contact support@verisoul.xyz</p>
            <p>To setup your organization for the first time, email henry@verisoul.xyz</p>
            <SignOutButton/>
        </div>
    )
}

export default NoOrgFound
