import React from 'react';
import { UserButton } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem', backgroundColor: '#f8f9fa', alignItems: 'center' }}>
            <div>
                <h1>Dashboard</h1>
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <Link to="/profile">Profile</Link>
                <Link to="/organization">Organization</Link>
                <UserButton />
            </div>
        </nav>
    );
};

export default Navbar;
