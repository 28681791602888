import React from "react";
import "./App.css";
import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    RedirectToSignIn,
    OrganizationProfile,
    OrganizationSwitcher,
    UserProfile,
    UserButton,
    ClerkLoaded,
    ClerkLoading,
} from "@clerk/clerk-react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Dashboard from "./views/dashboard";
import Navbar from "./navbar";
import Back from "./back";
import NoOrgFound from "./views/NoOrgFound";
import useUnload from "./useUnload";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard/>,
    },
    {
        path: "/profile",
        element: (<div style={{maxHeight: '90vh'}}>
            <Back/>
            <UserProfile/>
        </div>),
    },
    {
        path: "/organization",
        element: (<div style={{maxHeight: '90vh'}}>
            <Back/>
            <OrganizationProfile/>
        </div>),
    },
    {
        path: '/button',
        element: <UserButton/>,
    },
    {
        path: "/navbar",
        element: (<>
            <Back/>
            <Navbar/>
        </>),
    },
    {
        path: "/switch",
        element: <OrganizationSwitcher hidePersonal={true} createOrganizationUrl={""}
                                       createOrganizationMode={"navigation"}/>,
    },
    {
        path: "/no-org-found",
        element: <NoOrgFound/>,
    },
    {
        path: "*",
        element: <div>Not Found</div>,
    },
]);

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw "Missing Publishable Key"
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function App() {
    useUnload('Are you sure you want to leave this page?');

    return (
        <div className="App">
            <ClerkProvider publishableKey={clerkPubKey}>
                <ClerkLoaded>
                    <SignedIn>
                        <RouterProvider router={router}/>
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn/>
                    </SignedOut>
                </ClerkLoaded>
                <ClerkLoading>
                    <span className="loader"></span>
                </ClerkLoading>
            </ClerkProvider>
        </div>
    );
}

export default App;
