import React from 'react';
import {useNavigate} from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/");
    };

    return (
        <div style={{margin: '20px'}}>
            <button
                onClick={goBack}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'lightgrey',
                    borderRadius: '50%',
                    width: '50px',
                    height: '50px',
                    border: 'none',
                    cursor: 'pointer',
                }}>
                <span style={{fontSize: '24px', color: 'black'}}>X</span>
            </button>
        </div>
    );
};

export default BackButton;
