import Retool from 'react-retool';
import {useOrganization, useOrganizationList, useUser} from "@clerk/clerk-react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const {user: userData} = useUser();
    const navigate = useNavigate()
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [environment, setEnvironment] = useState(() => sessionStorage.getItem('v_environment') || 'production');

    const {organizationList, isLoaded, setActive} = useOrganizationList();
    const {
        organization: currentOrganization
    } = useOrganization();


    useEffect(() => {
        if (!currentOrganization && organizationList && organizationList.length > 0) {
            setActive({organization: organizationList[0].organization})
        }
    }, [currentOrganization, organizationList, isLoaded, setActive])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 2000)

    }, [loading])

    const onNavigate = (e) => {
        if (e && e.type === "retool-navigate") {
            navigate(e.route)
        } else if (e && e.type === "retool-environment") {
            setEnvironment(e.environment)
            sessionStorage.setItem('v_environment', e.environment);
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        } else if (e && e.type === "retool-org-switch") {
            console.log(e);
            let new_org_id = e.org_id
            let org = organizationList.find(o => o.organization.publicMetadata?.org_id === new_org_id)
            setActive({organization: org.organization})
        }
    }

    useEffect(() => {
        if (userData) {
            let orgs = userData.organizationMemberships
            if (!orgs || orgs.length === 0) {
                navigate('/no-org-found')
                return
            }
            console.log(userData);
            setUser(JSON.parse(JSON.stringify(userData)))
        }
    }, [userData])

    if (!user) {
        return <span className="loader"></span>
    }


    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            overflow: 'hidden'
        }}>
            {loading && <span className="loader"></span>}
            <div style={{
                opacity: loading ? 0 : 1,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                transition: 'opacity 1s linear',
                boxSizing: 'border-box'
            }}>
                <Retool
                    url={"https://verisoul.retool.com/embedded/public/a8e9b6af-d768-474e-8884-744bad1d7538?_environment=" + environment} // live
                    // url = {"https://verisoul.retool.com/apps/995e81e6-739b-11ee-a3bb-2360e66b1871/Dashboard_W_Clerk_V2?_releaseVersion=latest"} // testing
                    data={{user}}
                    height={'100%'}
                    width={'100%'}
                    onData={onNavigate}
                    style={{border: 'none', margin: 0, padding: 0, boxSizing: 'border-box'}}
                />
            </div>
        </div>
    );
}

export default Dashboard;
